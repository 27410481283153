<template>
    <div
        class="calendar__day__wrapper"
        :class="{ 'calendar__day__wrapper--disabled': !checkDay(day) }"
        @click="openModalOfDay(day, attributes)"
    >
        <div class="calendar__day__item">
            <span class="day-number">
                {{ day.day | digits($i18n.locale) }}
            </span>
            <div class="day-event-count">
                <span v-if="attributes && attributes.length && bookedCount(attributes)">
                    {{ bookedCount(attributes) | digits($i18n.locale) }}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'CalendarDay',

        props: {
            day       : [Object, Array],
            attributes: [Object, Array],
            minDate   : Date,
            maxDate   : Date,

            /**
             * Define the disable dates
             */
            disabledDates: [Object, Array],
        },

        methods: {
            /**
             * Open Modal
             *
             * @param day
             * @param attr
             */
            openModalOfDay(day, attr) {
                if (this.checkDay(day)) {
                    this.$emit('dayClicked', day, attr);
                }
            },

            /**
             * Count the booked of the day
             *
             * @param booked
             */
            bookedCount(booked) {
                return booked && booked.length ? booked[0].customData.count : 0;
            },

            /**
             * Check day conditions
             *
             * @param day
             */
            checkDay(day) {
                return this.checkRange(day.date) && this.checkDisabled(day);
            },

            /**
             * Check if the day is in the range
             *
             * @param day
             */
            checkRange(day) {
                return day >= this.minDate && day <= this.maxDate;
            },

            /**
             * Check if the day is disabled
             *
             * @param day
             */
            checkDisabled(day) {
                const timestamp = new Date(day.ariaLabel).getTime().toString().slice(0, 10);
                return this.disabledDates && !(this.disabledDates.includes(day.weekday - 1)
                    || this.disabledDates.includes(parseInt(timestamp, 10)));
            },
        },
    };
</script>
