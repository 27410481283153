<template>
    <div>
        <y-page-head
            :title="$t('fluorocarbon.calendar.title.calendar')"
            icon="md-calendar"
            :class="[{disabled: $wait.is('loading-page')}]"
        >
            <template slot="actions">
                <!-- Setting -->
                <y-button
                    key="calendar-setting-modal"
                    @click.prevent.native="$refs.calendarSettingModal.open()"
                >
                    {{ $t('button.setting') }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            class="panel-grid-layout"
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <y-calendar
                    v-if="calendar && metadata"
                    :rows="rows"
                    :columns="3"
                    is-expanded
                    title-position="center"
                    :first-day-of-week="7"
                    :attribute="attribute"
                    :min-date="timestapToDate(metadata.starts_at)"
                    :max-date="timestapToDate(metadata.ends_at)"
                    :disabled-dates="[...metadata.off_week_days, ...metadata.off_days]"
                    custom-calendar-component="YCalendarDay"
                    custom-class="custom-calendar"
                    @dayClicked="dayClicked"
                />

                <y-calendar-setting-modal
                    ref="calendarSettingModal"
                    @done="reFetch"
                />
                <y-calendar-day-booked-modal
                    ref="calendarDayBookedModal"
                    @done="reFetch"
                />
            </main>
        </y-loading>
    </div>
</template>

<script>
    import Vue from 'vue';
    import PageMixin from '@/mixins/Page';
    import { YCalendar } from '@deps/Calendar';
    import YCalendarDay from '@/modules/fluorocarbon/components/CalendarDay';
    import YCalendarSettingModal from '@/modules/fluorocarbon/components/CalendarSettingModal';
    import YCalendarDayBookedModal from '@/modules/fluorocarbon/components/CalendarDayBookedModal';

    Vue.component('YCalendarDay', YCalendarDay);

    export default {
        name: 'Calendar',

        components: {
            YCalendar,
            YCalendarSettingModal,
            YCalendarDayBookedModal,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('fluorocarbon.calendar.title.calendar'),
            };
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                calendar: null,
                metadata: null,
                dates   : null,

                // Rows and Columns of the calendar
                months: null,
            };
        },

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.fluorocarbon.calendar')),
            ];
        },

        computed: {
            /**
             * attribute of the calendar
             */
            attribute() {
                return [
                    ...this.dates,
                ];
            },

            /**
             * Return the rows of the Calendar
             */
            rows() {
                return this.months && this.months < 3 ? 1 : 2;
            },

            /**
             * Return the Disable Dated
             */
            disabledDates() {
                return { ordinalWeekdays: [1] };
            },
        },

        methods: {
            /**
             * Fetch Calendar Data
             */
            fetch() {
                return this.$services.Fluorocarbon.consultationDays().then((response) => {
                    this.calendar = response.data.results;
                    this.metadata = response.data.metadata;
                    this.monthDiff(this.timestapToDate(this.metadata.starts_at), this.timestapToDate(this.metadata.ends_at));
                    this.daysAttr(response.data.results);
                }).catch((error) => {
                    this.handleError(error);
                });
            },

            /**
             * Convert Timestamp to Date
             *
             * @param ts
             */
            timestapToDate(ts) {
                return new Date(new Date(ts * 1000));
            },

            /**
             * Count the months
             *
             * @param d1
             * @param d2
             */
            monthDiff(d1, d2) {
                let months;
                months = (d2.getFullYear() - d1.getFullYear()) * 12;
                months -= d1.getMonth();
                months += d2.getMonth();
                this.$set(this, 'months', months);
            },

            /**
             * Day clicked data
             *
             * @param day
             * @param attr
             */
            dayClicked(day, attr) {
                this.$refs.calendarDayBookedModal.open(day, attr);
            },

            /**
             * Create Days attr array
             *
             * @param dates
             */
            daysAttr(dates) {
                const attr = [];
                dates.forEach((day) => {
                    attr.push({
                        key       : day.id,
                        customData: {
                            count  : day.booked_count,
                            rawDate: day.effected_at,
                        },
                        dates: this.timestapToDate(day.effected_at),
                    });
                });
                this.dates = attr;
            },
        },
    };
</script>
