<template>
    <y-modal
        ref="Modal"
        class="calendar__booked"
        forced
        :title="$t('fluorocarbon.calendar.title.day_booked', [dayFormat])"
        @close="reset"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <div
                    v-if="metadata && metadata.available_times && metadata.available_times.length"
                    class="calendar__booked__times"
                >
                    <div
                        v-for="time in metadata.available_times"
                        :key="time.id"
                        class="calendar__booked__time"
                        :class="addSlotClass(time)"
                        @click="getBookData(time)"
                    >
                        <span>
                            {{ `${time.opens_time} - ${time.closes_time}` }}
                        </span>
                        <y-form-icon :icon="occupied(time) ? 'md-delete' : 'md-plus'" />
                    </div>
                </div>

                <!-- Book Details -->
                <div v-if="clickedSlot">
                    <y-panel
                        class="mb0 mt15"
                        :title="$t('fluorocarbon.calendar.booked.info_form_title')"
                    >
                        <form
                            id="book-form"
                            @submit.prevent="bookTime"
                        >
                            <div class="row">
                                <div class="col">
                                    <y-form-field
                                        v-model="model.name_first"
                                        type="text"
                                        name="name_first"
                                        :readonly="occupied(clickedSlot)"
                                    />
                                </div>
                                <div class="col">
                                    <y-form-field
                                        v-model="model.name_last"
                                        type="text"
                                        name="name_last"
                                        :readonly="occupied(clickedSlot)"
                                    />
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <y-form-field
                                        v-model="model.email"
                                        type="text"
                                        name="email"
                                        :readonly="occupied(clickedSlot)"
                                    />
                                </div>
                                <div class="col">
                                    <y-form-field
                                        v-model="model.mobile"
                                        type="number"
                                        name="mobile"
                                        :readonly="occupied(clickedSlot)"
                                    />
                                </div>
                            </div>
                            <div class="d-f align-items-center justify-content-end">
                                <y-button
                                    class="ml5"
                                    @click="cancelBookTime"
                                >
                                    {{ $t('button.cancel') }}
                                </y-button>
                                <y-button
                                    v-if="!occupied(clickedSlot)"
                                    type="submit"
                                    form="book-form"
                                    loading-on="book-form"
                                    color="blue"
                                >
                                    {{ $t('fluorocarbon.calendar.booked.button') }}
                                </y-button>
                                <y-button
                                    v-if="occupied(clickedSlot)"
                                    type="submit"
                                    form="book-form"
                                    loading-on="book-form"
                                    color="red"
                                >
                                    {{ $t('button.delete') }}
                                </y-button>
                            </div>
                        </form>
                    </y-panel>
                </div>
            </y-loading>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-modal"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { date } from '@nodes/helpers/date';
    import { YModal, YFormIcon, YFormField } from '@deps';

    export default {
        name: 'CalendarDayBookedModal',

        components: {
            YModal,
            YFormIcon,
            YFormField,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                day          : null,
                dayId        : null,
                attr         : null,
                booked       : null,
                bookedInfo   : null,
                metadata     : null,
                clickedSlot  : null,
                detailsOfBook: null,
                model        : {},
            };
        },

        computed: {
            /**
             * Return the human date
             */
            dayFormat() {
                return this.day ? date(this.day.customData.rawDate, 'dddd D MMMM YYYY', this.$i18n.locale) : '';
            },
        },

        methods: {
            /**
             * Open modal
             *
             * @param day
             * @param attr
             */
            open(day, attr) {
                if (attr && attr.length) {
                    this.$set(this, 'day', attr[0]);
                    this.$set(this, 'dayId', attr[0].key);
                }
                this.fetch(attr[0].key);
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.$emit('done');
            },

            /**
             * Reset data
             */
            reset() {
                this.day = null;
                this.dayId = null;
                this.attr = null;
                this.booked = null;
                this.bookedInfo = null;
                this.metadata = null;
            },

            /**
             * Fetch Booked
             *
             * @param id
             */
            fetch(id) {
                this.$wait.start('fetching-data');
                return this.$services.Fluorocarbon.consultationTimes({ id }).then((response) => {
                    this.booked = response.data.results.booked_ids;
                    this.bookedInfo = response.data.results.booked_details;
                    this.metadata = response.data.metadata;
                }).catch((error) => {
                    this.handleError(error);
                    this.close();
                }).finally(() => {
                    this.$wait.end('fetching-data');
                });
            },

            /**
             * Check if the time is occupied
             *
             * @param time
             */
            occupied(time) {
                return this.booked && this.booked.length && this.booked.includes(time.id);
            },

            /**
             * Attach the classes
             *
             * @param time
             */
            addSlotClass(time) {
                const classes = [];
                if (this.occupied(time)) {
                    classes.push('calendar__booked__time--occupied');
                }
                if (!this.occupied(time)) {
                    classes.push('calendar__booked__time--free');
                }
                if (this.clickedSlot && this.clickedSlot.id === time.id) {
                    classes.push('calendar__booked__time--clicked');
                }
                return classes;
            },

            /**
             * Get book details
             *
             * @param id
             */
            bookDetails(id) {
                let details;
                Object.keys(this.bookedInfo).forEach((key) => {
                    if (this.bookedInfo[key].id === id) {
                        details = this.bookedInfo[key];
                        this.detailsOfBook = details;
                        this.model = details;
                    }
                });
            },

            /**
             * Get Slot Data
             *
             * @param time
             */
            getBookData(time) {
                if (this.clickedSlot && (time.id === this.clickedSlot.id)) {
                    this.cancelBookTime();
                } else {
                    this.$set(this, 'clickedSlot', time);
                    this.bookDetails(time.id);
                }
            },

            /**
             * Cancel Book Time Form
             */
            cancelBookTime() {
                this.$set(this, 'clickedSlot', null);
                this.$set(this, 'detailsOfBook', null);
                this.model = {};
            },

            /**
             * Book Time
             */
            bookTime() {
                this.$wait.start('book-form');
                let params = {
                    id    : this.clickedSlot.id,
                    day   : this.dayId,
                    action: this.occupied(this.clickedSlot) ? 'free' : 'book',
                };
                if (!this.occupied(this.clickedSlot)) {
                    params = {
                        ...params,
                        ...this.model,
                    };
                }
                return this.$services.Fluorocarbon.consultationTimeBook(params).then(() => {
                    this.$toast.success(this.$t(`fluorocarbon.calendar.message.success.${this.occupied(this.clickedSlot) ? 'free' : 'book'}`));
                    this.fetch(this.dayId);
                    this.$set(this, 'clickedSlot', null);
                    this.$set(this, 'detailsOfBook', null);
                    this.model = {};
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('book-form');
                });
            },
        },
    };
</script>
