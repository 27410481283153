<template>
    <!-- eslint-disable vue/no-unused-vars -->
    <div class="calendar">
        <v-calendar
            :class="customClass"
            class="calendar__wrapper"
            :is-expanded="isExpanded"
            :rows="rows"
            :columns="columns"
            :attributes="attribute"
            :min-date="minDate"
            :max-date="maxDate"
            :disabled-dates="disabledDates"
            :title-position="titlePosition"
            :first-day-of-week="firstDayOfWeek"
            :masks="maskFormat"
            disable-page-swipe
        >
            <template #day-content="{ day, attributes }">
                <component
                    :is="customCalendarComponent"
                    :day="day"
                    :attributes="attributes"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :disabled-dates="disabledDates"
                    @dayClicked="dayClicked"
                />
            </template>
        </v-calendar>
    </div>
</template>

<script>
    import VCalendar from 'v-calendar/lib/components/calendar.umd';

    export default {
        name: 'Calendar',

        components: {
            VCalendar,
        },

        props: {
            /**
             * Number of calendar rows to display.
             */
            rows: Number,

            /**
             * Number of calendar columns to display.
             */
            columns: Number,

            /**
             * Position of the header title
             *
             * @values "left", "center", "right"
             */
            titlePosition: String,

            /**
             * Expands calendar to fill the full width of its container.
             */
            isExpanded: Boolean,

            /**
             * Custom Calendar Component Name
             */
            customCalendarComponent: String,

            /**
             * Custom Calendar Class
             */
            customClass: String,

            /**
             * Set the first day of the week
             */
            firstDayOfWeek: Number,

            /**
             * Attribute of the Calendar
             */
            attribute: [Object, Array],

            /**
             * Set the minimum date of the calendar
             */
            minDate: Date,

            /**
             * Set the maximum date of the calendar
             */
            maxDate: Date,

            /**
             * Define the disable dates
             */
            disabledDates: [Object, Array],
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                maskFormat: {
                    weekdays: 'WWW',
                },
            };
        },

        computed: {},

        methods: {
            /**
             * Emit the data after day Clicked
             *
             * @param {*} day
             * @param {*} attr
             */
            dayClicked(day, attr) {
                this.$emit('dayClicked', day, attr);
            },
        },
    };
</script>
