<template>
    <y-modal
        ref="Modal"
        class="calendar__setting"
        forced
        :title="$t('fluorocarbon.calendar.title.setting')"
    >
        <div slot="body">
            <y-loading
                :active="$wait.is('fetching-data')"
                height="50px"
            >
                <div class="row">
                    <div class="col-6">
                        <y-form-date-picker
                            v-model="model.starts_at"
                            type="date"
                            name="calendar.setting.starts_at"
                        />
                    </div>
                    <div class="col-6">
                        <y-form-date-picker
                            v-model="model.ends_at"
                            type="date"
                            :min="model.starts_at"
                            name="calendar.setting.ends_at"
                        />
                    </div>
                    <div class="col-6">
                        <y-form-date-picker
                            v-model="model.opens_time"
                            type="time"
                            format="HH:mm"
                            :jump-minute="15"
                            round-minute
                            name="calendar.setting.opening_time"
                        />
                    </div>
                    <div class="col-6">
                        <y-form-date-picker
                            v-model="model.closes_time"
                            type="time"
                            format="HH:mm"
                            :jump-minute="15"
                            round-minute
                            name="calendar.setting.closing_time"
                        />
                    </div>
                    <div class="col-6">
                        <y-form-date-picker
                            v-model="model.off_days"
                            type="date"
                            :min="model.starts_at"
                            :max="model.ends_at"
                            multi
                            name="calendar.setting.off_days"
                        />
                        <div
                            v-if="model.off_days"
                            class="form-label"
                        >
                            <y-date-label
                                v-for="d in model.off_days"
                                :key="d"
                                :element="{value: d, color: 'blue', forced: true, format: 'YYYY/MM/DD' }"
                            />
                        </div>
                    </div>
                    <div class="col-6">
                        <y-form-field
                            v-model="model.off_week_days"
                            type="select"
                            :options="days"
                            allow-empty
                            multi
                            name="calendar.setting.off_week_days"
                        />
                    </div>
                    <div class="col-6">
                        <y-form-field
                            v-model="model.show_months_limit"
                            type="number"
                            name="calendar.setting.show_months_limit"
                        />
                    </div>
                </div>
            </y-loading>
        </div>

        <div
            slot="footer"
            class="ta-l w-100"
        >
            <y-button
                class="ml5"
                disabled-on="saving-modal"
                @click.prevent.native="close"
            >
                {{ $t('button.back') }}
            </y-button>

            <y-button
                color="green"
                form="change-shipping-form"
                :loading="$wait.is('submitting-form')"
                @click.prevent.native="save"
            >
                {{ $t('button.save') }}
            </y-button>
        </div>
    </y-modal>
</template>

<script>
    import { YModal, YFormField, YFormDatePicker } from '@deps';
    import YDateLabel from '@deps/form/elements/DateLabel';

    export default {
        name: 'CalendarSettingModal',

        components: {
            YModal,
            YFormField,
            YFormDatePicker,
            YDateLabel,
        },

        /**
         * @inheritdoc
         */
        data() {
            return {
                model: {},

                // Days
                days: [
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.sunday'),
                        value: 0,
                    },
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.monday'),
                        value: 1,
                    },
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.tuesday'),
                        value: 2,
                    },
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.wednesday'),
                        value: 3,
                    },
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.thursday'),
                        value: 4,
                    },
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.friday'),
                        value: 5,
                    },
                    {
                        label: this.$t('fluorocarbon.calendar.booked.days.saturday'),
                        value: 6,
                    },
                ],
            };
        },

        methods: {
            /**
             * Open modal
             */
            open() {
                this.fetch();
                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.$refs.Modal.close();
                this.$emit('done');
            },

            /**
             * Fetch setting
             */
            fetch() {
                this.$wait.start('fetching-data');
                this.$services.Fluorocarbon.consultationSettingsFetch().then((response) => {
                    this.model = response.data.results;
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('fetching-data');
                });
            },

            /**
             * Save setting
             */
            save() {
                this.$wait.start('submitting-form');
                const params = {
                    ...this.model,
                    user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                };
                this.$services.Fluorocarbon.consultationSettingsSave(params).then(() => {
                    this.$toast.success(this.$t('fluorocarbon.calendar.setting.message.success'));
                    this.close();
                    this.$emit('done');
                }).catch((error) => {
                    this.handleError(error);
                }).finally(() => {
                    this.$wait.end('submitting-form');
                });
            },
        },
    };
</script>
